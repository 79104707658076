import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import { useProductContext } from "@app/providers/product"
import ProductItem from "@app/components/Product/ProductItem"
import { Icon } from "@app/components/Icon"

import { NormalisedCollection } from "@root/types/custom-types/Collection/Collection"
import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { FilterItem } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  title: string
  description: string
  selectLabel: string
  selectButtonLabel: string
  editLabel: string
  optionCollections: GatsbyTypes.SanityCollection[] | undefined
}

const ProductOption1Selection: React.FC<Props> = ({ title, description, selectLabel, selectButtonLabel, editLabel, optionCollections }) => {
  const { option1ModalOpen, setOption1ModalOpen, setProductBundle, productBundle } = useProductContext()
  const { getCollection, getHandle } = useShopify()

  const [collections, setCollections] = useState<NormalisedCollection[]>([])
  const [activeCategory, setActiveCategory] = useState("")
  const [items, setItems] = useState<NormalisedProduct[] | undefined>([])

  const fetchData = useMemo(
    () => async () => {
      if (!optionCollections) return

      setCollections([])

      const collectionResults = await Promise.all(
        optionCollections.map(collection =>
          getCollection({
            firstProducts: 16,
            firstVariants: 1,
            firstImages: 2,
            firstMedia: 2,
            firstCollections: 2,
            firstMetafields: 2,
            handle: getHandle(collection),
          })
        )
      )

      if (collectionResults.length) setCollections(collectionResults)
    },
    [optionCollections, getHandle, getCollection]
  )

  const filtersList = useMemo(
    () =>
      optionCollections
        ?.map(collection => {
          if (!collection.title || !collection.shopify?.handle) return

          return {
            label: collection.title,
            value: collection.shopify?.handle,
          }
        })
        .filter((c): c is FilterItem => c !== undefined),
    [optionCollections]
  )

  const optionProduct = useMemo(() => (productBundle.step1 ? productBundle.step1[0] : undefined), [productBundle.step1])

  const onOpenHandler = useCallback(() => setOption1ModalOpen(true), [setOption1ModalOpen])
  const onCloseHandler = useCallback(() => setOption1ModalOpen(false), [setOption1ModalOpen])
  const onFilterClick = useCallback((value: string) => setActiveCategory(value), [setActiveCategory])
  const onAddClickHandler = useCallback(
    (product: NormalisedProduct) => {
      setProductBundle(prevBundle => {
        return {
          ...prevBundle,
          ...{
            step1: [product],
          },
        }
      })
      onCloseHandler()
    },
    [setProductBundle, onCloseHandler]
  )

  // intentionally only run once at first render
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionCollections])

  useEffect(() => {
    const initialHandle = optionCollections?.length ? optionCollections[0].shopify?.handle || "" : ""
    setActiveCategory(initialHandle)
  }, [optionCollections])

  useEffect(() => {
    if (!collections.length) return
    const activeCollection = collections.find(collection => collection.handle === activeCategory) || collections[0]
    setItems(activeCollection.products || [])
  }, [collections, activeCategory, setItems])

  return (
    <Box mb={{ base: "5", lg: "6" }}>
      <Text size="smSemiLarge" color="typography.headlines900" mb="1.5">
        {selectLabel}
      </Text>

      {optionProduct ? (
        <Flex
          onClick={onOpenHandler}
          justifyContent="space-between"
          alignItems="center"
          gap="2"
          py="2"
          pr="4"
          pl="2"
          border="1px solid"
          borderColor="border.light"
          boxShadow="subtleBottomGlow"
          borderRadius="xl"
          cursor="pointer"
        >
          <Flex gap="2" alignItems="center">
            <AspectRatio ratio={1 / 1} w="10.5" h="10.5">
              <Image {...optionProduct.images[0]} />
            </AspectRatio>

            <Text size="textBase" flex={1}>
              {optionProduct.title}
            </Text>
          </Flex>

          <Text size="textLinkMedium" color="typography.body600">
            {editLabel}
          </Text>
        </Flex>
      ) : (
        <Button onClick={onOpenHandler} variant="solidSecondary" size="lg" w="full">
          {selectButtonLabel}
        </Button>
      )}

      <Modal onClose={onCloseHandler} isOpen={option1ModalOpen} size="option1">
        <ModalOverlay bg="background.overlay" />

        <ModalContent>
          <ModalBody p="0">
            <IconButton
              onClick={onCloseHandler}
              variant="unstyled"
              icon={<Icon name="ui/close" width="24px" height="24px" />}
              aria-label={"Close Drawer"}
              pos="absolute"
              top="4"
              right="4"
              bg="background.white"
              zIndex="overlay"
              d="inline-flex"
              justifyContent="center"
              alignItems="center"
              w="10"
              h="10"
              borderRadius="full"
              boxShadow="subtleBottomGlow"
              _focus={{ boxShadow: "subtleBottomGlow" }}
            />

            <VStack h="full" spacing="0">
              <Box
                w={{ base: "100%", md: "calc(100% - 96px)" }}
                pt={{ base: "6", lg: "12" }}
                pb={{ lg: "6" }}
                borderBottom={{ lg: "solid 1px" }}
                borderColor={{ lg: "border.default" }}
                mx={{ lg: "auto" }}
                textAlign="center"
              >
                <Box w="full" px={{ base: "6", lg: "12" }} mb={{ base: "3", lg: "6" }}>
                  {title ? (
                    <Heading size="h4" color="typography.headlines900" maxW="150" mx="auto" mb={{ md: "1.5" }}>
                      {title}
                    </Heading>
                  ) : null}

                  {description ? (
                    <Text size="largeParagraph" maxW="150" mx="auto">
                      {description}
                    </Text>
                  ) : null}
                </Box>

                {filtersList ? (
                  <Box overflow="hidden" w="full">
                    <Flex
                      overflow="auto"
                      scrollSnapType="x mandatory"
                      scrollPadding={{ base: "4", lg: "0" }}
                      sx={{
                        scrollbarWidth: { base: "none" },
                        "::-webkit-scrollbar": {
                          display: { base: "none" },
                        },
                      }}
                      pb="1"
                      justifyContent={{ md: "center" }}
                    >
                      {filtersList.map(item => {
                        const isActive = item?.value === activeCategory

                        return item?.value ? (
                          <Text
                            key={item.value}
                            as="span"
                            d="inline-block"
                            onClick={() => onFilterClick(item?.value)}
                            size="textBase"
                            flexShrink={0}
                            scrollSnapAlign="start"
                            py="1.5"
                            px="4.5"
                            mr="3"
                            boxShadow="subtleBottomGlow"
                            border="1px solid"
                            borderRadius="3xxl"
                            cursor="pointer"
                            _first={{ ml: { base: "4", lg: "0" } }}
                            _last={{ mr: { base: "4", lg: "0" } }}
                            fontWeight={isActive ? "bold" : "normal"}
                            color={isActive ? "background.white" : "typography.headlines900"}
                            bg={isActive ? "brand.primary" : "background.white"}
                            borderColor={isActive ? "transparent" : "border.light"}
                          >
                            {item?.label}
                          </Text>
                        ) : null
                      })}
                    </Flex>
                  </Box>
                ) : null}
              </Box>

              <Box flex={{ md: 1 }} overflow={{ md: "auto" }}>
                <Grid
                  gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                  gap={{ base: "4", lg: "5" }}
                  py={{ base: "4", md: "6" }}
                  px={{ base: "4", md: "8", lg: "12" }}
                >
                  {items && items.length
                    ? items.map(product => <ProductItem key={product.id} item={product} onAddHandler={onAddClickHandler} />)
                    : [...Array(4)].map((i, index) => <Skeleton key={index} isLoaded={false} w="full" pb="calc(100% + 188px)" />)}
                </Grid>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
export default React.memo(ProductOption1Selection)
