import React, { useEffect, useRef } from "react"
import { Box, Container, Flex, VStack, Text, Link } from "@chakra-ui/react"

import { useMedia } from "@app/hooks/useMedia"
import { NormalisedShopifyProduct } from "@app/hooks/useShopify"

import ReviewsLite from "@app/components/Reviews/ReviewsLite"
import ProductAddToCart from "@app/components/Product/ProductAddToCart"
import ProductDescription from "@app/components/Product/ProductDescription"
import ProductInformationItem, { InformationItem } from "@app/components/Product/ProductInformationItem"
import ProductLabels from "@app/components/Product/ProductLabels"
import ProductMedia from "@app/components/Product/ProductMedia"
import ProductTemplatePrice from "@app/components/Product/ProductTemplatePrice"
import ProductTitle from "@app/components/Product/ProductTitle"
import ReviewRating from "@app/components/Sections/Reviews/ReviewRating"
import { useAppContext } from "@app/providers/app"
import { useProductContext } from "@app/providers/product"
import { useReviews } from "@app/hooks/useReviews"

import ProductOptions, { OptionsAdditional, ProductOptionsData } from "@app/components/Product/ProductOptions"
import { MatchingLabel, PersonaliserLabel, CustomiserData } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  menuTitle: string
  product: NormalisedShopifyProduct
  compareText: string
  addToCart: string
  loading: boolean
  informationItems: InformationItem[]
  matchingLabels: MatchingLabel[] | undefined
  backToProduct: string
  reviewsTitle: string
  optionsAdditional: OptionsAdditional
  productOptionsData: ProductOptionsData
  labels: PersonaliserLabel[]
  customiserData: CustomiserData
  productReviewStarsLink: string
}

const ProductDetails: React.FC<Props> = ({
  menuTitle,
  product,
  compareText,
  addToCart,
  loading,
  informationItems,
  matchingLabels,
  backToProduct,
  reviewsTitle,
  optionsAdditional,
  productOptionsData,
  labels,
  customiserData,
  productReviewStarsLink,
}) => {
  const { isLarge } = useMedia()
  const { saveProductItems } = useAppContext()
  const { templateAddToCartRef } = useProductContext()
  const { useStoreReviews } = useReviews()
  const reviewsResponse = useStoreReviews()

  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  const seperator = !isLarge ? <Box h="4" borderTop="1px solid" borderColor="border.accentTop" bg="background.grey100" /> : null

  return (
    <Container as="section" ref={sectionRef} maxW="container.2xl" px="0" bg="background.white">
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        pt={{ lg: "8" }}
        pb={{ lg: "24.5" }}
        px={{ base: "0", lg: "20" }}
        gap={{ lg: "3.8125%" }}
      >
        <Box w={{ base: "full", lg: "63.375%" }}>
          <ProductMedia product={product} />
        </Box>

        <Box w={{ base: "full", lg: "32.8125%" }}>
          <Box
            pt={{ base: "5" }}
            pb={{ base: "5", lg: "6" }}
            px={{ base: "4", lg: "0" }}
            borderBottom={{ lg: "1px solid" }}
            borderColor={{ lg: "border.default" }}
          >
            <ProductLabels product={product} matchingLabels={matchingLabels} />
            <ProductTitle product={product} />
            <ProductTemplatePrice loading={loading} compareText={compareText} />
            {reviewsResponse?.bottomline?.total_review > 0 && (
              <Box display="flex" alignItems="center">
                <ReviewRating score={reviewsResponse?.bottomline?.average_score} round="up" />
                <Link href={`/${productReviewStarsLink}`} ml="10px" color={"#0000EE"}>
                  {reviewsResponse?.bottomline?.total_review} Reviews
                </Link>
              </Box>
            )}
          </Box>

          {seperator}

          <Box ref={templateAddToCartRef}>
            <ProductDescription product={product} />

            <Box px={{ base: "4", lg: "0" }} pb="6">
              <ProductOptions
                optionsAdditional={optionsAdditional}
                productOptionsData={productOptionsData}
                labels={labels}
                customiserData={customiserData}
              />

              <ProductAddToCart product={product} buttonLabel={addToCart} />

              {informationItems.length ? (
                <VStack spacing="3" alignItems="flex-start">
                  {informationItems.map(item => (
                    <ProductInformationItem key={item.ctaText.toLowerCase()} item={item} backToProduct={backToProduct} />
                  ))}
                </VStack>
              ) : null}
            </Box>
          </Box>

          {isLarge ? (
            <ReviewsLite id={`reviews-lite-${product.handle}-primary`} title={reviewsTitle} reviewsResponse={reviewsResponse} />
          ) : null}

          {seperator}
        </Box>
      </Flex>
    </Container>
  )
}

export default React.memo(ProductDetails)
