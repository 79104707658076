import React, { useCallback, useMemo, useState } from "react"
import { AspectRatio, Box, Button, Center, Flex, Grid, Image, Spinner, Text } from "@chakra-ui/react"

import { useProductContext } from "@app/providers/product"
import CustomAlert from "@app/components/CustomAlert"
import { Icon } from "@app/components/Icon"
import ProductLabelSelectionModal from "./ProductLabelSelectionModal"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { useShopify } from "@app/hooks/useShopify"

import { PersonaliserLabel, CustomiserData, CustomLabelKey, CustomLabel, ModalImage } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  selectLabel: string
  description: string | undefined
  requiredMessage: string
  personalisedButtonLabel: string
  edit: string
  labelProducts: PersonaliserLabel[] | undefined
  labels: PersonaliserLabel[]
  modalTitle: string
  modalDescription: string
  enlargeProduct: string
  labelSubtitle: string
  selectProduct: string
  customiserData: CustomiserData
  labelFinaliseMessage: string
  labelFinaliseWarning: string
  labelTagLarge: string
  labelTagSmall: string
}

const ProductLabelSelection: React.FC<Props> = ({
  selectLabel,
  description,
  requiredMessage,
  personalisedButtonLabel,
  edit,
  labelProducts,
  labels,
  modalTitle,
  modalDescription,
  enlargeProduct,
  labelSubtitle,
  selectProduct,
  customiserData,
  labelFinaliseMessage,
  labelFinaliseWarning,
  labelTagLarge,
  labelTagSmall,
}) => {
  const { productBundle, setProductBundle, setLabelModalOpen, setModalImages, setIsImageModalOpen, labelLoading } = useProductContext()
  const { getFirstAvailableVariant } = useShopify()

  const [step, setStep] = useState(1)

  const filteredLabels = useMemo(() => {
    if (!productBundle.step1?.length) return labels

    const step1ItemTags = productBundle.step1[0].tags
    const labelSizeTag = step1ItemTags.includes(labelTagLarge) ? labelTagLarge : step1ItemTags.includes(labelTagSmall) ? labelTagSmall : ""

    return labelSizeTag ? labels.filter(i => i.product.tags.includes(labelSizeTag)) : labels
  }, [labels, productBundle, labelTagLarge, labelTagSmall])

  const isCustom = useMemo(() => {
    if (!productBundle.step3) return false

    const keys = ["textLine1", "textLine2", "photoUpload"] as CustomLabelKey[]
    const keysCheck = keys.reduce(
      (acc, key) => {
        if (!productBundle.step3) return [...acc, false]
        if (productBundle.step3[key]) return [...acc, true]
        return [...acc, false]
      },
      [false]
    )

    return keysCheck.includes(true)
  }, [productBundle])

  const onLabelSelection = useCallback(
    (product: NormalisedProduct) => {
      if (!productBundle.step1 || labelLoading) return

      // To match the personaliser data structure we're gonna fake some object data
      const emptyColor = {
        hex: "",
        rgb: {
          r: 0,
          g: 0,
          b: 0,
        },
      }

      const customLabel: CustomLabel = {
        textLine1: "",
        textLine2: "",
        textColor: emptyColor,
        backgroundColor: emptyColor,
        photoUpload: "",
        finalImage: product.images[0].src,
        labelProduct: product,
      }

      setProductBundle(prevBundle => {
        return {
          ...prevBundle,
          ...{
            step2: undefined,
          },
          ...{
            step3: customLabel,
          },
        }
      })
    },
    [productBundle.step1, setProductBundle, labelLoading]
  )

  const onOpenHandler = useCallback(() => {
    if (isCustom) {
      setStep(2)
    } else {
      setStep(1)
    }

    setLabelModalOpen(true)
  }, [isCustom, setLabelModalOpen, setStep])

  const onLabelEnlargeHandler = useCallback(
    (images: ModalImage[] | undefined) => {
      if (!images) return

      setModalImages(images)
      setIsImageModalOpen(true)
    },
    [setModalImages, setIsImageModalOpen]
  )

  const onDefaultLabelEnlarge = useCallback(
    (images: ModalImage[]) => {
      if (!productBundle.step1 || labelLoading) return
      onLabelEnlargeHandler(images)
    },
    [productBundle.step1, onLabelEnlargeHandler, labelLoading]
  )

  return labels?.length ? (
    <Box pb={{ base: "5", lg: "6" }} borderBottom="1px solid" borderColor="border.default" mb={{ base: "5", lg: "6" }}>
      <Text size="smSemiLarge" color="typography.headlines900" mb="1.5">
        {selectLabel}
      </Text>

      {description ? (
        <Text size="mediumParagraph" mb="3">
          {description}
        </Text>
      ) : null}

      {!productBundle.step1 ? (
        <CustomAlert status="info" mb="3">
          <Text size="mediumParagraph">{requiredMessage}</Text>
        </CustomAlert>
      ) : null}

      {labelLoading && labelFinaliseWarning ? (
        <CustomAlert status="warning" withoutIcon={true} mb="3">
          <Text size="mediumParagraph">{labelFinaliseWarning}</Text>
        </CustomAlert>
      ) : null}

      {productBundle.step3 && isCustom ? (
        <Flex
          onClick={onOpenHandler}
          justifyContent="space-between"
          alignItems="center"
          gap="2"
          pr="4"
          border="2px solid"
          borderColor="brand.primary"
          mb="3"
          boxShadow="subtleBottomGlow"
          borderRadius="xl"
          cursor="pointer"
          overflow="hidden"
        >
          <Flex gap="3" alignItems="center">
            <AspectRatio ratio={1 / 1} w="14" h="14">
              <Image src={productBundle.step3.finalImage} />
            </AspectRatio>

            <Text size="textBase" flex={1} py="2">
              {personalisedButtonLabel}
            </Text>
          </Flex>

          <Text size="textLinkMedium" color="typography.body600">
            {edit}
          </Text>
        </Flex>
      ) : labelLoading ? (
        <Flex
          onClick={onOpenHandler}
          justifyContent="space-between"
          alignItems="center"
          gap="2"
          pr="4"
          border="2px solid"
          borderColor="transparent"
          mb="3"
          boxShadow="subtleBottomGlow"
          borderRadius="xl"
          cursor="pointer"
          overflow="hidden"
        >
          <Flex gap="3" alignItems="center">
            <Box pos="relative" w="14" h="14">
              <Center pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w="calc(100% - 8px)" h="auto" maxH="100%">
                <Spinner thickness="2px" speed="0.4s" emptyColor="background.white" color="brand.secondaryFocus" size="md" />
              </Center>
            </Box>

            {labelFinaliseMessage ? (
              <Text size="textBase" flex={1} py="2">
                {labelFinaliseMessage}
              </Text>
            ) : null}
          </Flex>
        </Flex>
      ) : (
        <Button onClick={onOpenHandler} variant="outlineSecondary" size="lg" w="full" mb="3" disabled={!productBundle.step1}>
          {personalisedButtonLabel}
        </Button>
      )}

      {labelProducts ? (
        <Grid templateColumns="repeat(2, 1fr)" columnGap="2" rowGap="3">
          {labelProducts.map(label => {
            const product = label.product
            const availableVariant = getFirstAvailableVariant(product)
            if (!availableVariant) return null

            const isLabelActive = !isCustom && productBundle?.step3 && productBundle.step3.labelProduct.id === product.id

            return product.images.length ? (
              <Flex
                key={product.id}
                alignItems="center"
                gap="2"
                border="2px solid"
                borderColor={isLabelActive ? "brand.primary" : "border.light"}
                boxShadow={!productBundle.step1 || labelLoading ? "none" : "subtleBottomGlow"}
                borderRadius="xl"
                overflow="hidden"
                cursor={!productBundle.step1 || labelLoading ? "default" : "pointer"}
                _hover={
                  !productBundle.step1 || labelLoading
                    ? {
                        lg: {
                          span: {
                            opacity: "0",
                          },
                        },
                      }
                    : {
                        lg: {
                          span: {
                            opacity: "1",
                          },
                        },
                      }
                }
              >
                <Box
                  onClick={() => onDefaultLabelEnlarge(product.images)}
                  pos="relative"
                  cursor={!productBundle.step1 || labelLoading ? "default" : "pointer"}
                >
                  <AspectRatio ratio={1 / 1} w="14" h="full">
                    <Image {...product.images[0]} />
                  </AspectRatio>

                  <Center
                    as="span"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="docked"
                    w="full"
                    h="full"
                    bg="background.overlay50"
                    opacity="0"
                    transition={{ lg: "0.2s opacity ease" }}
                  >
                    <Box w="3.5" h="3.5" color="typography.white">
                      <Icon name="ui/zoom" width="100%" height="100%" />
                    </Box>
                  </Center>
                </Box>

                <Box onClick={() => onLabelSelection(product)} flex={1} py="2.5" px="2">
                  <Text size="smallParagraph" color={!productBundle.step1 || labelLoading ? "typography.body500" : "typography.body700"}>
                    {product.title}
                  </Text>
                </Box>
              </Flex>
            ) : null
          })}
        </Grid>
      ) : null}

      <ProductLabelSelectionModal
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        labelSubtitle={labelSubtitle}
        enlargeProduct={enlargeProduct}
        selectProduct={selectProduct}
        labels={filteredLabels}
        onLabelEnlargeHandler={onLabelEnlargeHandler}
        customiserData={customiserData}
        step={step}
        setStep={setStep}
      />
    </Box>
  ) : null
}
export default React.memo(ProductLabelSelection)
