import React from "react"
import { Box, Flex, Heading, Text, RadioGroup, Radio, Image } from "@chakra-ui/react"
import DatePicker from "react-date-picker"

import CustomAlert from "@app/components/CustomAlert"

import { RadioHandler } from "@app/components/Product/ProductOptions"
import { PersonaliserError } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  location: "personaliser" | "product"
  title: string | undefined
  description: string | undefined
  product: GatsbyTypes.Maybe<GatsbyTypes.SanityProductFragmentFragment>
  options: string[]
  datePlaceholder: string
  isSelected: string
  radioHandler: RadioHandler
  onDateChangeHandler: (date: Date) => void
  stickerDate: Date | undefined
  dateError?: PersonaliserError
}

const PersonaliserStickerSelection: React.FC<Props> = ({
  location,
  title,
  description,
  product,
  options,
  datePlaceholder,
  isSelected,
  radioHandler,
  onDateChangeHandler,
  stickerDate,
  dateError,
}) => {
  const stickerImage = product?.image

  return (
    <Box
      p={{ base: "4", lg: location !== "product" ? "8" : "4" }}
      boxShadow="subtleBottomGlow"
      borderRadius="xl"
      mb={location !== "product" ? { base: "4", lg: "8" } : { base: "5", lg: "6" }}
    >
      <Flex gap={{ base: "4" }} mb="3">
        <Box>
          {title ? (
            <Heading size="headlines" color="typography.headlines900" mb="1">
              {title}
            </Heading>
          ) : null}

          {description ? <Text size="largeParagraph">{description}</Text> : null}
        </Box>

        {stickerImage ? <Image src={stickerImage} alt="" htmlWidth={72} htmlHeight={72} w="18" h="18" /> : null}
      </Flex>

      {options.length ? (
        <RadioGroup
          key={`radio-${isSelected}`}
          defaultValue={isSelected}
          name="sticker"
          onChange={value => radioHandler(value, "sticker")}
          display="flex"
          gap="4"
        >
          {options.map((option, index) => (
            <Radio key={option.toLowerCase()} value={index === 0 ? "true" : "false"}>
              {option}
            </Radio>
          ))}
        </RadioGroup>
      ) : null}

      {datePlaceholder && isSelected == "true" ? (
        <Box mt="3">
          <DatePicker
            clearIcon={null}
            format="dd/MM/y"
            minDate={new Date(Date.now())}
            onChange={onDateChangeHandler}
            returnValue="end"
            value={stickerDate}
          />
        </Box>
      ) : null}

      {dateError && dateError.active ? (
        <CustomAlert status="error" withoutIcon={true} mt="3">
          {dateError.message}
        </CustomAlert>
      ) : null}
    </Box>
  )
}
export default React.memo(PersonaliserStickerSelection)
