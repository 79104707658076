/* eslint-disable prettier/prettier */
import React, { useMemo } from "react"

import { useLocationContext } from "@app/providers/location"
import { useShopifyProductRaw, useShopifyProductLive, NormalisedShopifyProduct, useShopify } from "@app/hooks/useShopify"

import { useCore } from "@app/hooks/useCore"
import ProductDetails from "@app/components/Product/ProductDetails"
import ProductDrawer from "@app/components/Product/ProductDrawer"
import ProductImageLightbox from "@app/components/Product/ProductImageLightbox"
import ProductStickyAddToCart from "@app/components/Product/ProductStickyAddToCart"

import type { PageProps } from "@root/types/global"
import { InformationItem } from "@app/components/Product/ProductInformationItem"
import { OptionsAdditional } from "@app/components/Product/ProductOptions"
import {
  PersonaliserLabel,
  PersonaliserLabelCategory,
  CropperShape,
  CustomiserData,
  Color,
} from "@root/types/custom-types/Personaliser/Data"

export type SanityProductDocument = GatsbyTypes.Maybe<
  {
    metadata: GatsbyTypes.SanityProduct["_rawMetadata"]
    siblings: GatsbyTypes.SanityProduct["_rawSiblings"]
  } & {
    readonly includedProducts: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityProductFragmentFragment>>>
    readonly option1Collections: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityCollectionFragmentFragment>>>
    readonly option2Products: GatsbyTypes.Maybe<ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.SanityProductFragmentFragment>>>
    readonly giftBoxProduct: GatsbyTypes.SanityProductFragmentFragment
    readonly accordionBlocks: GatsbyTypes.Maybe<
      ReadonlyArray<
        GatsbyTypes.Maybe<
          Pick<GatsbyTypes.SanityAccordionSlide, "active" | "title"> & { content: GatsbyTypes.SanityAccordionSlide["_rawContent"] }
        >
      >
    >
  } & GatsbyTypes.SanityProductFragmentFragment
>

export type ProductTemplateSectionProps = {
  sanityProduct: SanityProductDocument
  shopifyProduct: NormalisedShopifyProduct
}

const Product: React.FC<PageProps<GatsbyTypes.TemplateProductQuery>> = ({
  product: sanityProduct,
  template,
  productLabels,
  personaliserFields,
  labels,
}) => {
  const {
    helpers: { ErrorBoundary, edgeNormaliser },
  } = useCore()
  const { productNormaliser } = useShopify()
  const { locating } = useLocationContext()
  const { product: rawProduct } = useShopifyProductRaw(sanityProduct)
  // TODO: Pull this from the product context to reduce double API call
  const { product: liveProduct, loading } = useShopifyProductLive(rawProduct)

  const product = liveProduct || rawProduct
  const isLoading = loading || locating

  const data = useMemo(() => edgeNormaliser(template), [edgeNormaliser, template])
  const sections = data.sections

  const informationItems = useMemo(
    () =>
      template?.additionalInformationItems?.length
        ? template.additionalInformationItems.map(
            (item): InformationItem => ({
              ctaIcon: item?.ctaIcon as unknown as GatsbyTypes.SanityImage,
              ctaText: item?.ctaText as string,
              drawerImage: item?.drawerImage as unknown as GatsbyTypes.SanityImage | undefined,
              drawerTitle: item?.drawerTitle,
              drawerText: item?.drawerText as string,
              drawerLink: item?.drawerLink,
            })
          )
        : [],
    [template?.additionalInformationItems]
  )

  const matchingLabels = useMemo(
    () =>
      productLabels?.matchingIcons?.map(item => ({
        matchingTag: item?.matchingTag?.toLowerCase() || "",
        svgIcon: item?.svgIcon as unknown as GatsbyTypes.SanityImage,
      })),
    [productLabels]
  )

  // Validated as required in Sanity, but Gatsby doesn't know that. Casting to match validation.
  const compareText = useMemo(() => template?.additionalCompareText as string, [template?.additionalCompareText])
  const addToCart = useMemo(() => template?.additionalAddToCart as string, [template?.additionalAddToCart])

  const optionsAdditional: OptionsAdditional = {
    option1Title: personaliserFields?.step1Title as string,
    option1Description: personaliserFields?.step1Description as string,
    labelModalTitle: personaliserFields?.step2Title as string,
    labelModalDescription: personaliserFields?.step2Description as string,
    selectOptionLabel: template?.additionalSelectOptionLabel as string,
    selectOptionButtonLabel: template?.additionalSelectOptionButtonLabel as string,
    editOptionLabel: template?.additionalEditOptionLabel as string,
    selectLabelLabel: template?.additionalSelectLabelLabel as string,
    selectLabelDescription: template?.additionalSelectLabelDescription,
    requiredMessage: template?.additionalRequiredMessage as string,
    personalisedButtonLabel: template?.additionalPersonalisedButtonLabel as string,
    enlargeProduct: personaliserFields?.additionalEnlargeProduct || "",
    labelSubtitle: personaliserFields?.additionalLabelSubtitle || "",
    selectProduct: personaliserFields?.additionalSelectProduct || "",
    sticker: {
      title: personaliserFields?.messagingStickerTitle,
      description: personaliserFields?.messagingStickerDescription,
      options: personaliserFields?.messagingStickerOptions as string[],
      datePlaceholder: personaliserFields?.messagingStickerDatePlaceholder as string,
      product: personaliserFields?.messagingStickerProduct,
    },
    giftcard: {
      title: personaliserFields?.messagingGiftCardTitle,
      description: personaliserFields?.messagingGiftCardDescription,
      options: personaliserFields?.messagingGiftCardOptions as string[],
      label: personaliserFields?.messagingGiftCardLabel,
      messagePlaceholder: personaliserFields?.messagingGiftCardMessagePlaceholder,
      product: personaliserFields?.messagingGiftCardProduct,
    },
    labelFinaliseMessage: personaliserFields?.additionalBuildingLabelMessage || "",
    labelFinaliseWarning: personaliserFields?.additionalBuildingLabelWarning || "",
    labelTagLarge: personaliserFields?.step2LargeLabelTag || "",
    labelTagSmall: personaliserFields?.step2SmallLabelTag || "",
  }

  // To match the editor data format we're gonna fake that these labels are personaliser labels instead of Shopify labels
  const labelsProductData: PersonaliserLabel[] | undefined = sanityProduct?.option2Products
    ?.map(sanityProduct => {
      return sanityProduct?.shopify?.raw
        ? {
            title: sanityProduct?.title as string,
            uuid: "",
            customiserControls: [],
            image: sanityProduct?.image || "",
            categories: [],
            product: productNormaliser(JSON.parse(sanityProduct?.shopify?.raw || "")),
            cropperShape: "rect",
            cropperAspectRatio: "1/1",
          }
        : null
    })
    .filter(Boolean) as PersonaliserLabel[]

  const productOptionsData = {
    option1: sanityProduct?.option1Collections as unknown as GatsbyTypes.SanityCollection[] | undefined,
    option2: labelsProductData,
    giftbox: sanityProduct?.giftBoxProduct?.shopify?.raw
      ? productNormaliser(JSON.parse(sanityProduct.giftBoxProduct.shopify.raw || ""))
      : undefined,
  }

  const labelsData: PersonaliserLabel[] = labels.edges.map(({ node }) => ({
    title: node.title as string,
    uuid: node.uuid as string,
    customiserControls: (node.customiserControls as string[]) || [""],
    image: node.image as unknown as GatsbyTypes.SanityCustomImage,
    categories: node.categories?.map(category => ({
      title: category?.title as string,
      order: category?.order as number,
      handle: {
        current: category?.handle?.current as string,
      },
    })) as PersonaliserLabelCategory[],
    product: productNormaliser(JSON.parse(node.labelProduct?.shopify?.raw || "")),
    cropperShape: (node.cropperShape as CropperShape | undefined) || "rect",
    cropperAspectRatio: node.cropperAspectRatio || "1/1",
  }))

  const customiserData: CustomiserData = {
    title: personaliserFields?.step3Title || "",
    description: personaliserFields?.step3Description || "",
    image: {
      buttonLabel: personaliserFields?.step3ImageButtonLabel || "",
      title: personaliserFields?.step3ImageUploadTitle || "",
      description: personaliserFields?.step3ImageUploadDescription || "",
      smallDescription: personaliserFields?.step3ImageUploadSmallDescription || "",
      uploadButtonLabel: personaliserFields?.step3UploadButtonLabel || "",
      uploadLimit: personaliserFields?.step3ImageUploadLimit || 1,
      uploadErrorMessage: personaliserFields?.step3ImageUploadErrorMessage || "",
    },
    text: {
      buttonLabel: personaliserFields?.step3TextButtonLabel || "",
      topLineLabel: personaliserFields?.step3TextTopLineLabel || "",
      bottomLineLabel: personaliserFields?.step3TextBottomLineLabel || "",
      messageLabel: personaliserFields?.step3TextMessageLabel || "",
    },
    design: {
      buttonLabel: personaliserFields?.step3DesignButtonLabel || "",
      textColorLabel: personaliserFields?.step3TextColourLabel || "",
      textColors: personaliserFields?.step3TextColours as Color[],
      backgroundColorLabel: personaliserFields?.step3BackgroundColourLabel || "",
      backgroundColors: personaliserFields?.step3BackgroundColours as Color[],
    },
    edit: personaliserFields?.step3EditLabel || "",
    confirm: personaliserFields?.step3Confirm || "",
    loading: {
      upload: personaliserFields?.step3LoadingMessageUpload || "",
      customise: personaliserFields?.step3LoadingMessageCustomise || "",
    },
    imageUploadFailedErrorMessage: personaliserFields?.step3ImageUploadFailedErrorMessage || "",
  }

  const reviewsPageLink = useMemo(
    () => template?.additionalProductReviewStarsLink?.[0]?.handle?.current || "",
    [template?.additionalProductReviewStarsLink]
  )

  return (
    <>
      <ProductDetails
        menuTitle={template?.additionalProductDetailsMenuTitle as string}
        product={product}
        compareText={compareText}
        addToCart={addToCart}
        loading={isLoading}
        informationItems={informationItems}
        matchingLabels={matchingLabels}
        backToProduct={template?.additionalBackToProductDetails as string}
        reviewsTitle={template?.additionalReviewsTitle as string}
        optionsAdditional={optionsAdditional}
        productOptionsData={productOptionsData}
        labels={labelsData}
        customiserData={customiserData}
        productReviewStarsLink={reviewsPageLink as string}
      />

      <ErrorBoundary>
        {sections.map((section: any) => {
          const name = section._type?.replace("section", "")
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          const SectionComponent = require(`../Sections/${name}/${name}`).default

          return (
            <SectionComponent
              key={section._key?.toString()}
              name={name}
              sanityProduct={sanityProduct}
              shopifyProduct={product}
              {...section}
            />
          )
        })}
      </ErrorBoundary>

      <ProductStickyAddToCart product={product} compareText={compareText} addToCart={addToCart} loading={isLoading} />
      <ProductDrawer backToOptions={template?.additionalBackToProductDetails as string} matchingLabels={matchingLabels} />
      <ProductImageLightbox />
    </>
  )
}
export default React.memo(Product)
