import React from "react"
import { Box, Text } from "@chakra-ui/react"

import { useShopifyPrice } from "@app/hooks/useShopify"
import { useAppContext } from "@app/providers/app"

type Props = {
  compareText: string
}

const ProductComparePrice: React.FC<Props> = ({ compareText }) => {
  const { activeVariant } = useAppContext()
  const { formattedCompareAtPrice } = useShopifyPrice(activeVariant)

  const compare =
    compareText && formattedCompareAtPrice
      ? compareText.replace("{price}", formattedCompareAtPrice)
      : formattedCompareAtPrice
      ? formattedCompareAtPrice
      : undefined

  return compare ? (
    <Box
      pos="relative"
      d="inline-flex"
      justifyContent="center"
      alignItems="center"
      py="1"
      px="2"
      bg="functional.error"
      borderRadius="sm"
      _before={{
        content: '""',
        pos: "absolute",
        top: "50%",
        left: "-1.5",
        transform: "translateY(-50%)",
        zIndex: "overlay",
        d: "block",
        w: "0",
        h: "0",
        borderStyle: "solid",
        borderWidth: "4px 6px 4px 0",
        borderColor: "transparent",
        borderRightColor: "functional.error",
      }}
    >
      <Text size="smallParagraph" fontWeight="bold" color="functional.errorContent" transform="translateY(1px)">
        {compare}
      </Text>
    </Box>
  ) : null
}

export default React.memo(ProductComparePrice)
