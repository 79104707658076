import React, { useMemo } from "react"
import { Button } from "@chakra-ui/react"

import { NormalisedShopifyProduct, useShopify } from "@app/hooks/useShopify"
import { useProductContext } from "@app/providers/product"
import { useCheckoutContext } from "@app/providers/checkout"

type Props = {
  product: NormalisedShopifyProduct
  buttonLabel: string
}

const ProductAddToCart: React.FC<Props> = ({ buttonLabel }) => {
  const { checkout } = useCheckoutContext()
  const { loading, bundleValid, bundlePrice, addProductBundleToCart, labelLoading } = useProductContext()
  const { formatMoney } = useShopify()

  const price = useMemo(() => formatMoney(bundlePrice || 0, checkout?.currencyCode), [bundlePrice, checkout?.currencyCode, formatMoney])
  const text = useMemo(() => buttonLabel.replace("{price}", price), [buttonLabel, price])

  return (
    <Button
      onClick={() => addProductBundleToCart()}
      disabled={!bundleValid || loading || labelLoading}
      isLoading={loading}
      size="lg"
      w="full"
      maxW="100"
      mx="auto"
      mb="3"
    >
      {text}
    </Button>
  )
}

export default React.memo(ProductAddToCart)
