import React from "react"
import { Box, Heading, Text, RadioGroup, Radio, FormControl, Textarea, FormLabel } from "@chakra-ui/react"

import { PersonaliserError } from "@app/hooks/usePersonaliser"
import CustomAlert from "@app/components/CustomAlert"
import { MessageChangeHandler, RadioHandler } from "@app/components/Product/ProductOptions"

type Props = {
  location: "personaliser" | "product"
  title: string | undefined
  description: string | undefined
  options: string[]
  isSelected: string
  label: string | undefined
  messagePlaceholder: string | undefined
  messageError?: PersonaliserError
  radioHandler: RadioHandler
  onMessageChangeHandler: MessageChangeHandler
  message: string | undefined
}

const PersonaliserGiftCardSelection: React.FC<Props> = ({
  location,
  title,
  description,
  options,
  isSelected,
  label,
  messagePlaceholder,
  messageError,
  radioHandler,
  onMessageChangeHandler,
  message,
}) => {
  return (
    <Box
      p={{ base: "4", lg: location !== "product" ? "8" : "4" }}
      mb={location !== "product" ? "0" : { base: "5", lg: "6" }}
      boxShadow="subtleBottomGlow"
      borderRadius="xl"
    >
      <Box mb={{ base: "3", lg: "6" }}>
        <Box>
          {title ? (
            <Heading size="headlines" color="typography.headlines900" mb="1">
              {title}
            </Heading>
          ) : null}

          {description ? <Text size="largeParagraph">{description}</Text> : null}
        </Box>
      </Box>

      {options.length ? (
        <RadioGroup
          key={`giftcard-${isSelected}`}
          defaultValue={isSelected}
          name="giftcard"
          onChange={value => radioHandler(value, "giftcard")}
          display="flex"
          gap="4"
        >
          {options.map((option, index) => (
            <Radio key={option.toLowerCase()} value={index === 0 ? "true" : "false"}>
              {option}
            </Radio>
          ))}
        </RadioGroup>
      ) : null}

      {isSelected === "true" ? (
        <Box mt="6">
          {label ? (
            <Text size="largeParagraph" mb="1.5">
              {label}
            </Text>
          ) : null}

          {messagePlaceholder ? (
            <FormControl>
              <Textarea placeholder=" " onChange={onMessageChangeHandler} value={message} />
              <FormLabel>{messagePlaceholder}</FormLabel>
            </FormControl>
          ) : null}
        </Box>
      ) : null}

      {messageError && messageError.active ? (
        <CustomAlert status="error" withoutIcon={true} mt="3">
          {messageError.message}
        </CustomAlert>
      ) : null}
    </Box>
  )
}
export default React.memo(PersonaliserGiftCardSelection)
